import { KeysMap } from '@/utils/types'

export const httpCode: KeysMap<any> = {
    '400': '您的请求有些问题',
    '401': '您的请求认证失败，请重新登录',
    '403': '不允许访问该资源吖',
    '404': '没有找到请求资源哦',
    '500': '貌似，服务器内部资源出故障了',
    '503': '抱歉，现在我在忙，请稍后再试'
}
export const ignoreErrorCode: string[] = []
// HTTP_400_BAD_REQUEST = 400
// HTTP_401_UNAUTHORIZED = 401
// HTTP_402_PAYMENT_REQUIRED = 402
// HTTP_403_FORBIDDEN = 403
// HTTP_404_NOT_FOUND = 404
// HTTP_405_METHOD_NOT_ALLOWED = 405
// HTTP_406_NOT_ACCEPTABLE = 406
// HTTP_407_PROXY_AUTHENTICATION_REQUIRED = 407
// HTTP_408_REQUEST_TIMEOUT = 408
// HTTP_409_CONFLICT = 409
// HTTP_410_GONE = 410
// HTTP_411_LENGTH_REQUIRED = 411
// HTTP_412_PRECONDITION_FAILED = 412
// HTTP_413_REQUEST_ENTITY_TOO_LARGE = 413
// HTTP_414_REQUEST_URI_TOO_LONG = 414
// HTTP_415_UNSUPPORTED_MEDIA_TYPE = 415
// HTTP_416_REQUESTED_RANGE_NOT_SATISFIABLE = 416
// HTTP_417_EXPECTATION_FAILED = 417
// HTTP_422_UNPROCESSABLE_ENTITY = 422
// HTTP_423_LOCKED = 423
// HTTP_424_FAILED_DEPENDENCY = 424
// HTTP_428_PRECONDITION_REQUIRED = 428
// HTTP_429_TOO_MANY_REQUESTS = 429
// HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE = 431
// HTTP_451_UNAVAILABLE_FOR_LEGAL_REASONS = 451
// HTTP_500_INTERNAL_SERVER_ERROR = 500
// HTTP_501_NOT_IMPLEMENTED = 501
// HTTP_502_BAD_GATEWAY = 502
// HTTP_503_SERVICE_UNAVAILABLE = 503
// HTTP_504_GATEWAY_TIMEOUT = 504
// HTTP_505_HTTP_VERSION_NOT_SUPPORTED = 505
// HTTP_507_INSUFFICIENT_STORAGE = 507
// HTTP_511_NETWORK_AUTHENTICATION_REQUIRED = 511
